import React, { useState, useRef, useEffect } from "react"
import LinkTransition from "../common/link-transition"
import bodymovin from "lottie-web"

import { Card } from "./styled.js"

const FeatureCard = props => {
  const [animationState, setAnimationState] = useState(true)
  const [animationInst, setAnimationInst] = useState(null)

  let lottieVisual = useRef()

  let lottieAnimation = useRef()
  let lottieAnimationInst = null

  useEffect(() => {
    if (lottieVisual && lottieVisual.current) {
      bodymovin.loadAnimation({
        useSubFrames: false,
        wrapper: lottieVisual.current,
        renderer: "svg",
        autoplay: false,
        loop: false,
        path: props.visualLottie,
      })
    }

    if (lottieAnimation && lottieAnimation.current) {
      lottieAnimationInst = bodymovin.loadAnimation({
        useSubFrames: false,
        wrapper: lottieAnimation.current,
        renderer: "svg",
        autoplay: false,
        loop: false,
        path: props.lottie,
      })
      setAnimationInst(lottieAnimationInst)
    }
  }, [])

  return (
    <Card
      onMouseEnter={() => {
        setAnimationState(false)
        animationInst.goToAndPlay(0, false)
      }}
      onMouseLeave={() => {
        setAnimationState(true)
        animationInst.stop()
      }}
    >
      <div
        className={`visual-wrapper${!animationState ? " animation-play" : ""}`}
      >
        {props.visual ? (
          <div className="visual">
            <img
              src={props.visual}
              alt={`Visual of ${props.headline ? props.headline : "feature"}`}
            />
          </div>
        ) : null}
        {props.visualLottie ? (
          <div className="visual">
            <div
              className="lottieVisual"
              ref={lottieVisual}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ) : null}
        {props.lottie ? (
          <div className="animation-wrapper">
            <div
              className="lottieAnimation"
              ref={lottieAnimation}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ) : null}
      </div>
      <div className="content">
        {props.headline ? (
          <span className="headline">{props.headline}</span>
        ) : null}
        {props.title ? (
          <h3 className="title">
            {props.title}
            {props.soon ? <span className="tag-soon">Soon</span> : null}
          </h3>
        ) : null}
        {props.text ? <p className="text">{props.text}</p> : null}
        {props.url ? (
          <LinkTransition to={props.url} className="link">
            {props.link}
            <svg
              width="23"
              height="10"
              viewBox="0 0 23 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7053 6.63864V8.60241C16.7053 9.70557 18.0391 10.258 18.8191 9.47798L22.021 6.27614C22.5046 5.79258 22.5045 5.00857 22.021 4.525L18.8191 1.32316C18.0391 0.543113 16.7053 1.09558 16.7053 2.19874V4.16215H1.8464C1.16254 4.16215 0.608154 4.71653 0.608154 5.40039C0.608154 6.08425 1.16254 6.63864 1.8464 6.63864H16.7053Z"
              />
            </svg>
          </LinkTransition>
        ) : null}
      </div>
      {props.url ? (
        <LinkTransition to={props.url} className="full-link" />
      ) : null}
    </Card>
  )
}

export default FeatureCard
