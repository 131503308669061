import styled from 'styled-components';
import variables, {fs, screenSize} from '../../utils/variables';

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background: ${variables.colors.white} none;
  border-radius: 8px;
  box-shadow: -1px 9px 20px rgba(116, 116, 188, 0.25);
  transition: all .3s ease-in;

  .full-link {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .visual-wrapper  {
    position: relative;
    padding: 40px 50px 25px;

    ${screenSize('padding-left', 40)}
    ${screenSize('padding-right', 40)}

    .animation-wrapper {
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      bottom: 25px;
      opacity: 0;
      transition: opacity .7s ease-in-out;

      img,
      svg {
        display: block;
      }
    }

    .visual {
      transition: opacity .7s ease-in-out;

      img,
      svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.animation-play {
      .visual {
        opacity: 0;
      }
      .animation-wrapper {
        opacity: 1;
      }
    }
  }

  .visual {
    img {
      margin-bottom: 0;
    }
  }

  .content {
    padding: 25px 50px 50px 50px;

    ${screenSize('padding-left', 40)}
    ${screenSize('padding-right', 40)}

  }

  .title {
    display: flex;
    align-items: flex-start;
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.5;
    color: ${variables.colors.backgroundDark};
    transition: all .3s ease-in;

    ${fs(24)}

    .tag-soon {
      display: inline-block;
      padding: 5px 11px;
      margin-left: 10px;
      border-radius: 4px;
      white-space: nowrap;
      font-family: 'Bossa', sans-serif;
      font-weight: 500;
      line-height: 1.1;
      color: ${variables.colors.white};
      background: ${variables.colors.blue};
      ${fs(16)}
    }
  }

  .text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.5;
    color: ${variables.colors.greyDarker};

    ${fs(16)}

    margin-bottom: 0;
  }

  .link {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    color: ${variables.colors.red};
    text-decoration: none;
    transition: all .3s ease-in;

    ${fs(16)}

    svg {
      width: 18px;
      height: 8px;
      margin-left: 10px;

      path {
        fill: ${variables.colors.red};
      }
    }

    &:hover {
      color: ${variables.colors.blue};
      
      svg,
      svg path {
        fill: ${variables.colors.blue};
      }
    }
  }

  /* &:hover {
    box-shadow: 0px 0px 15px rgba(116, 116, 188, 0.25);

    .title {
      color: ${variables.colors.blue};
    }

    .link {
      color: ${variables.colors.blue};
      
      svg,
      svg path {
        fill: ${variables.colors.blue};
      }
    }
  } */
`;