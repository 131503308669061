import React, { useState, useEffect, useRef } from "react"
import LinkTransition from "../components/common/link-transition"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import { Section } from "../components/common/section"

import Hero from "../components/hero"
import FeatureDetail from "../components/feature-detail"
import FeatureCard from "../components/feature-card"
// import Tabs from '../components/tabs';
import Feature from "../components/feature"
import Ready from "../components/ready"

import illuProductCollab from "../images/illu-product-collab.svg"
import illuProductDesign from "../images/illu-product-design.svg"
import illuReady from "../images/illu-ready.svg"

import bodymovin from "lottie-web"
// import ScrollTrigger from 'react-scroll-trigger';
// import animationBackground from '../animations/Content-shape-left.json';

import otherFeatureVisualContent from "../images/other-features.svg"

const ProductTour = () => {
  const collaborationAnimation =
    "/animations/2020-09-Ludus-01-collaboration.json"
  const collaborationStatic =
    "/animations/2020-09-Ludus-01-collaboration-static.json"

  const smartMenuAnimation = "/animations/2020-09-Ludus-02-smartMenu.json"
  const smartMenuStatic = "/animations/2020-09-Ludus-02-smartMenu-static.json"

  const chartsAnimation = "/animations/2020-09-Ludus-03-charts_EDIT01.json"
  const chartsStatic = "/animations/2020-09-Ludus-03-charts-static.json"

  const channelsAnimation = "/animations/2020-09-Ludus-04-channels_EDIT01.json"
  const channelsStatic = "/animations/2020-09-Ludus-04-channels-static.json"

  const fontsAnimation = "/animations/2020-09-Ludus-05-fonts_EDIT01.json"
  const fontsStatic = "/animations/2020-09-Ludus-05-fonts-static.json"

  const speakerAnimation = "/animations/2020-09-Ludus-06-speaker_EDIT01.json"
  const speakerStatic = "/animations/2020-09-Ludus-06-speaker-static.json"

  const smartBlockAnimation = "/animations/2020-09-Ludus-07-smartBlock.json"
  const smartBlockStatic = "/animations/2020-09-Ludus-07-smartBlock-static.json"

  const templateAnimation = "/animations/2020-09-Ludus-08-template.json"
  const templateStatic = "/animations/2020-09-Ludus-08-template-static.json"

  const copyPasteAnimation =
    "/animations/2020-09-Ludus-09-copyPaste_EDIT01.json"
  const copyPasteStatic = "/animations/2020-09-Ludus-09-copyPaste-static.json"

  const embedAnimation = "/animations/2020-09-Ludus-10-embed_EDIT01.json"
  const embedStatic = "/animations/2020-09-Ludus-10-embed-static.json"

  const shareAnimation = "/animations/2020-09-Ludus-11-share_EDIT01.json"
  const shareStatic = "/animations/2020-09-Ludus-11-share-static.json"

  const cloudAnimation = "/animations/2020-09-Ludus-12-cloud.json"
  const cloudStatic = "/animations/2020-09-Ludus-12-cloud-static.json"

  const blendingAnimation = "/animations/2020-10-Ludus-blending.json"
  const blendingStatic = "/animations/2020-10-Ludus-blending-static.json"

  let animationBackground = useRef()

  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null,
  })

  const params = {
    slidesPerView: 1.15,
    spaceBetween: 20,
    loop: false,
    centeredSlides: true,
    // loopedSlides: 5,
    autoHeight: false,
    rebuildOnUpdate: false,
  }

  useEffect(() => {
    if (
      animationBackground &&
      animationBackground.current &&
      !animationBackground.current.loaded
    ) {
      animationBackground.current.loaded = true
      bodymovin.loadAnimation({
        useSubFrames: false,
        wrapper: animationBackground.current,
        animType: "svg",
        autoplay: true,
        loop: false,
        path: "/animations/Content-shape-left.json",
      })
    }
  }, [currentBreakPoint])

  // const [testAnimationDirection, setTestAnimationDirection] = useState(1);
  // const [testAnimationIsPaused, setTestAnimationIsPaused] = useState(false);

  const renderSlider = children => {
    if (
      currentBreakPoint.bp === "xs" ||
      currentBreakPoint.bp === "s" ||
      currentBreakPoint.bp === "m"
    ) {
      return <Swiper {...params}>{children}</Swiper>
    } else {
      return children
    }
  }

  const renderCardsOrganize = () => {
    const items = [
      <FeatureCard
        lottie={channelsAnimation}
        visualLottie={channelsStatic}
        title="Channels"
        text="Thanks to the channels you can define different workspaces with different user rights. That means you can keep your sensitive presentations confidential. You can also work in your very own channel when you want to play it solo. "
      />,
      <FeatureCard
        lottie={collaborationAnimation}
        visualLottie={collaborationStatic}
        title="Live Collaboration"
        text="Fluidify your team’s workflow with the ability to simultaneously edit the same presentation with your coworkers."
      />,
      <FeatureCard
        lottie={cloudAnimation}
        visualLottie={cloudStatic}
        title="3d-party syncing "
        text="Collaboration is not just about Ludus. It's also how Ludus itself interact with other players. Let's check how you can seamlessly add Ludus to your current ecosystem."
      />,
    ]
    return items.map((item, index) => (
      <div className="item" key={index}>
        {item}
      </div>
    ))
  }

  const renderCardsDesign = () => {
    const items = [
      <FeatureCard
        lottie={blendingAnimation}
        visualLottie={blendingStatic}
        title="Magic editing"
        text="You can pretty much do anything with all types of content blocks: crop, corner radius, drop shadow, filters, blend modes or even skew! And you can apply these features to an image, a GIF or even a video. We really want to let you follow your limitless imagination. "
      />,
      <FeatureCard
        lottie={templateAnimation}
        visualLottie={templateStatic}
        title="Templates"
        text="Ludus templates are arranged by themes. So you can change the theme of a presentation in one click. And thanks to our system of object variables you can build these templates and themes by yourself. Our system let you precisely define which object is editable in the template."
      />,
      <FeatureCard
        lottie={fontsAnimation}
        visualLottie={fontsStatic}
        title="Font management"
        text="Of course you can use Google fonts but we believe great people use great fonts so you can also upload your favorite fonts to Ludus (TTF or OTF). And like everything else in Ludus, that font will be available to everyone in your team. No more font management nightmares!"
      />,
      <FeatureCard
        lottie={smartBlockAnimation}
        visualLottie={smartBlockStatic}
        title="Smart blocks"
        text="You can see smart blocks as a way to quickly save, share and re-use assets across your team and presentations."
      />,
    ]
    return items.map((item, index) => (
      <div className="item" key={index}>
        {item}
      </div>
    ))
  }

  const renderCardsConnect = () => {
    const items = [
      <FeatureCard
        lottie={smartMenuAnimation}
        visualLottie={smartMenuStatic}
        title="Smart Menu"
        text={`The smart menu is the fastest way to find one of your saved asset or to find a new type of block you want to add. For example if you search for "image" you'll end up with the different third-party services we support such as Unsplash, Instagram, Flickr or Iconscout. `}
      />,
      <FeatureCard
        lottie={copyPasteAnimation}
        visualLottie={copyPasteStatic}
        title="Copy/paste anything"
        text={`When you paste a URL, we automatically detect if we support the service. So for example if you copy and paste a YouTube URL, Ludus will create a video block in your slide. `}
      />,
      <FeatureCard
        lottie={chartsAnimation}
        visualLottie={chartsStatic}
        title="Charts"
        text={`You can paste tabular data (from your favorite spreadsheet tool) to generate a chart.`}
      />,
    ]
    return items.map((item, index) => (
      <div className="item" key={index}>
        {item}
      </div>
    ))
  }

  const renderCardsPitch = () => {
    const items = [
      <FeatureCard
        lottie={shareAnimation}
        visualLottie={shareStatic}
        title="Custom domain and URL"
        text={`With our custom domain and URL feature, you can make Ludus your very own, and nobody will never now you used Ludus when you share your presentation.`}
      />,
      <FeatureCard
        lottie={embedAnimation}
        visualLottie={embedStatic}
        title="Embed anywhere"
        text={`You can easily embed Ludus presentations on your website, as easily as you would embed a YouTube video.`}
      />,
      <FeatureCard
        lottie={speakerAnimation}
        visualLottie={speakerStatic}
        title="Ludus Vox"
        text={`Don’t let your slides speak for themselves ever again. With Ludus Vox, you can record yourself on top of your slides to add more context to your presentation, the same way you would pitch live to an audience.`}
      />,
    ]
    return items.map((item, index) => (
      <div className="item" key={index}>
        {item}
      </div>
    ))
  }

  return (
    <Layout
      currentPage="product-tour"
      socialDark={true}
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Product tour" />

      <Section>
        <div
          style={{
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? "58%" : "26%",
            height: "39%",
            top: currentBreakPoint.type === "mobile" ? "250px" : "190px",
            right: "0",
          }}
        >
          <div>
            <div
              className="animationBackground"
              ref={animationBackground}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </div>

        <Hero>
          <div className="content">
            <h1 className="title">
              Simple and easy or highly creative, there’s a way for everybody
            </h1>
            <div className="text">
              <p>Whatever slide your build, let's make it a bit magical.</p>
            </div>
          </div>
        </Hero>
      </Section>

      <Section>
        <div
          style={{
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? "58%" : "23%",
            paddingBottom: currentBreakPoint.type === "mobile" ? "60%" : "23%",
            top: "30%",
            left: "0",
            background: `transparent url(${illuProductCollab}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />

        <FeatureDetail>
          <div className="intro intro-double">
            <span className="intro-headline">1. Organize together</span>
            <h2 className="intro-title">
              Work together on presentation was never this easy. And not just
              good luck easy...
            </h2>
            <div className="intro-text intro-text-cols">
              <p>
                We make it very easy and reliable to work together one the same
                presentation. In Ludus we try to find the right balance between
                being able to work all together live and the moment when you
                need some sense of control or more privacy.
              </p>
              <p>
                We let you define some boundaries for that collaboration so you
                remain in full control. No more bad surprises.
              </p>
            </div>
            <LinkTransition to="/organize-together" className="link">
              Our collaboration features
              <svg
                width="23"
                height="10"
                viewBox="0 0 23 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.7053 6.63864V8.60241C16.7053 9.70557 18.0391 10.258 18.8191 9.47798L22.021 6.27614C22.5046 5.79258 22.5045 5.00857 22.021 4.525L18.8191 1.32316C18.0391 0.543113 16.7053 1.09558 16.7053 2.19874V4.16215H1.8464C1.16254 4.16215 0.608154 4.71653 0.608154 5.40039C0.608154 6.08425 1.16254 6.63864 1.8464 6.63864H16.7053Z"
                />
              </svg>
            </LinkTransition>
          </div>
          {renderSlider(renderCardsOrganize())}
        </FeatureDetail>
      </Section>

      <Section light={true}>
        <div
          style={{
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? "58%" : "23%",
            paddingBottom: currentBreakPoint.type === "mobile" ? "60%" : "23%",
            top: currentBreakPoint.type === "mobile" ? "8%" : "-15%",
            right: "0",
            background: `transparent url(${illuProductDesign}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />
        <FeatureDetail>
          <div className="intro intro-double">
            <span className="intro-headline">2. Create without limits</span>
            <h2 className="intro-title">
              When creatives can put together presentations the way they would
              with other creative tools
            </h2>
            <div className="intro-text intro-text-cols">
              <p>
                Every designer should feel at home with our editor. Keyboard
                shortcuts, workflow, UI, everything should feel familiar to
                them. So they can instantly be productive and worry less about
                their mental health and can proudly say "I like building
                presentation now".
              </p>
            </div>
            <LinkTransition to="/create-without-limits" className="link">
              Our editing features
              <svg
                width="23"
                height="10"
                viewBox="0 0 23 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.7053 6.63864V8.60241C16.7053 9.70557 18.0391 10.258 18.8191 9.47798L22.021 6.27614C22.5046 5.79258 22.5045 5.00857 22.021 4.525L18.8191 1.32316C18.0391 0.543113 16.7053 1.09558 16.7053 2.19874V4.16215H1.8464C1.16254 4.16215 0.608154 4.71653 0.608154 5.40039C0.608154 6.08425 1.16254 6.63864 1.8464 6.63864H16.7053Z"
                />
              </svg>
            </LinkTransition>
          </div>
          {renderSlider(renderCardsDesign())}
        </FeatureDetail>
      </Section>

      <Section>
        <FeatureDetail>
          <div className="intro intro-double">
            <span className="intro-headline">3. Connect the blocks</span>
            <h2 className="intro-title">
              In Ludus you tell your story by connecting blocks together. Yes,
              that easy.
            </h2>
            <div className="intro-text intro-text-cols">
              <p>
                In our app, every piece of content is a block with very similar
                property. It can be an image from your computer, a youtube
                video, a google map, a 3d object, a VR experiment... Embedding
                and designing these elements has never been that easy. We pushed
                it even further by letting your design team design custom blocks
                as well (header, images, charts, etc), so you can forget rigid
                templates.
              </p>
            </div>
            <LinkTransition to="/connect-the-blocks" className="link">
              Our content features
              <svg
                width="23"
                height="10"
                viewBox="0 0 23 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.7053 6.63864V8.60241C16.7053 9.70557 18.0391 10.258 18.8191 9.47798L22.021 6.27614C22.5046 5.79258 22.5045 5.00857 22.021 4.525L18.8191 1.32316C18.0391 0.543113 16.7053 1.09558 16.7053 2.19874V4.16215H1.8464C1.16254 4.16215 0.608154 4.71653 0.608154 5.40039C0.608154 6.08425 1.16254 6.63864 1.8464 6.63864H16.7053Z"
                />
              </svg>
            </LinkTransition>
          </div>

          {renderSlider(renderCardsConnect())}
        </FeatureDetail>
      </Section>

      <Section light={true}>
        <FeatureDetail>
          <div className="intro intro-double">
            <span className="intro-headline">4. Pitch in peace</span>
            <h2 className="intro-title">
              Present the way you like: live at the biggest event, remotely, or
              even without being there (yup).
            </h2>
            <div className="intro-text intro-text-cols">
              <p>
                Presenting live is always nice, but these days being able to
                present remotely is essential. That's why sharing is at the core
                of Ludus, you can define a custom URL, even a custom domain if
                you wish. Sharing your presentation is just about sending a
                link. You can even record a video of yourself on top of each
                slide to make sure your story is always received the right way!
              </p>
            </div>
            <LinkTransition to="/pitch-in-peace" className="link">
              Our present features
              <svg
                width="23"
                height="10"
                viewBox="0 0 23 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.7053 6.63864V8.60241C16.7053 9.70557 18.0391 10.258 18.8191 9.47798L22.021 6.27614C22.5046 5.79258 22.5045 5.00857 22.021 4.525L18.8191 1.32316C18.0391 0.543113 16.7053 1.09558 16.7053 2.19874V4.16215H1.8464C1.16254 4.16215 0.608154 4.71653 0.608154 5.40039C0.608154 6.08425 1.16254 6.63864 1.8464 6.63864H16.7053Z"
                />
              </svg>
            </LinkTransition>
          </div>

          {renderSlider(renderCardsPitch())}
        </FeatureDetail>
      </Section>

      {/* <Section>
        <Tabs
          title="And so so so many other stuff"
          startTab={1}
          data={otherFeatures}
          type={(currentBreakPoint.bp === 'xs' || currentBreakPoint.bp === 's') ? 'dropdown' : 'tabs'}
        />
      </Section> */}

      <Section>
        {currentBreakPoint.type !== "mobile" ? (
          <div
            style={{
              position: "absolute",
              zIndex: "1",
              width: "39%",
              paddingBottom: "61%",
              bottom: "0",
              left: "0",
              background: `transparent url(${otherFeatureVisualContent}) no-repeat 0 50%`,
              backgroundSize: "contain",
            }}
          />
        ) : null}

        <Feature
          reverse={true}
          extraspace={true}
          title="There is so much more!"
          text="We can't list you every single one of our feature can we? You really want us to do it? Ok so here you go..."
          url="/all-features"
          link="See everything"
        />
      </Section>

      <Section dark={true}>
        <div
          style={{
            display:
              currentBreakPoint.bp === "m" ||
              currentBreakPoint.bp === "s" ||
              currentBreakPoint.bp === "xs"
                ? "none"
                : "block",
            position: "absolute",
            zIndex: "0",
            width: "35%",
            paddingBottom: "35%",
            bottom: "0",
            right: "0",
            background: `transparent url(${illuReady}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />

        <Ready dark={true} />
      </Section>
    </Layout>
  )
}

export default ProductTour
